import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKalina as author } from 'data/authors';
import styled from 'styled-components';

import img from 'img/blog/psycho-principles/psychology_ux.png';
import img1 from 'img/blog/psychological-principles/doginbox.jpg';
import img2 from 'img/blog/psychological-principles/workonproject.jpg';
import img3 from 'img/blog/psychological-principles/dots.png';
import img4 from 'img/blog/psychological-principles/ls2.png';
import img5 from 'img/blog/psychological-principles/rocketlink.png';
import img6 from 'img/blog/psychological-principles/twopersons.png';
import img7 from 'img/blog/psychological-principles/chatbotize.png';
import img8 from 'img/blog/psychological-principles/cloudtalk.png';
import img9 from 'img/blog/psychological-principles/netflix.png';
import img10 from 'img/blog/psychological-principles/ls1.png';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    color: #777;
    font-weight: 700;
    font-size: 1rem;
  }
  .action-banner {
    background-color: #7cffef;
    padding: 16px 24px;
    font-size: 18px;
    text-decoration: underline;
    color: #000;
    width: 100%;
    display: block;
    margin: 1.5rem 0;
    font-weight: 700;
    border-radius: 4px;
  }
`;

const Content = () => {
  return (
    <Container>
      <p>UX and psychology go hand in hand.</p>
      <p>
        User experience professionals are supposed to answer the needs of the people, find their
        main pain points and solve their problems through design. This is why understanding human
        behavior is essential to create world-class products. Once you’re familiar with the basic
        principles of psychology, designing experiences feels less like taking a shot in the dark.
        You’ll find it easier to spot the areas for improvement and come up with ideas rooted in
        psychological theories.
      </p>
      <p>
        In this article, we’re going to discuss the fields of psychology that can be useful for UX
        designers, researchers and other usability professionals. To make these tips more
        actionable, we’ve picked 7 of the most compelling psychological principles related to user
        experience.
      </p>
      <p>
        Ready to find out how psychology can help you become a better UX professional? Keep on
        reading to learn more!
      </p>
      <h2>Which areas of psychology are interesting for user experience professionals</h2>
      <p>
        Apart from clinical applications and psychotherapy, psychology comes with a wide range of
        tools to help people in their everyday life. Some of the areas that can be particularly
        useful for UX people include:
      </p>
      <h3>Behavioral psychology</h3>
      <img
        src={img1}
        alt="dog-in-box"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Dog in the box"
      />
      <p>
        <strong>What is is</strong>: Dating back to the 19th century,{' '}
        <strong>behavioral psychology</strong> (also known as behavioral science) explores patterns
        so fundamental that they can be applied both to humans and animals. The core assumption is
        that all behaviors are either a response to certain stimuli or they come from a history of
        reinforcements and punishments. In other words, an individual’s reaction depends on their
        previous interactions with the environment.
      </p>
      <p>
        <strong>Classic example:</strong>{' '}
        <a
          href="https://rocketlink.io/blog/how-to-create-powerful-ctas-a-guide-for-beginners/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pavlov’s dog{' '}
        </a>{' '}
        – a textbook example of <strong>classical conditioning</strong>. Ivan Pavlov, a Russian
        psychologist, expected that the dogs would salivate when they receive food. He found out
        that they start drooling as soon as they hear the footsteps of the assistant who’s bringing
        the snacks. Later, he designed an experiment that involved ringing a bell while the dogs
        were fed. After that, the dogs started salivating as soon as they heard a bell, even when
        food was not involved. This behavior is known as a <strong>conditioned response</strong>.
      </p>
      {/* DODAĆ ANCHOR DO AKAPITU */}
      <p>
        <strong>UX-based example</strong>: Jakob’s law – people expect your website to work the same
        way as the other websites they’re using.
      </p>
      <h3>Cognitive psychology</h3>
      <img
        src={img2}
        alt="women-work"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Work on project"
      />
      <p>
        <strong>What is is</strong>: While the previously discussed approach is focused entirely on
        behavior, <strong>cognitive psychology</strong> takes other mental processes into account.
        Among other things, it researches attention, perception and problem solving. To put it
        simply, it deals not only with how people behave but also with how they think. <br />{' '}
        <strong>Classic example:</strong>{' '}
        <a
          href="https://www.khanacademy.org/test-prep/mcat/social-sciences-practice/social-science-practice-tut/e/miller-s-law--chunking--and-the-capacity-of-working-memory"
          target="_blank"
          rel="noopener noreferrer"
        >
          Miller’s Law
        </a>{' '}
        – George Miller found out that an average person’s memory span is limited to around 7 (plus
        or minus 2) pieces of information. This fact can be useful for UX designers as well. For
        example, if you need to display more than 7 items on the website, it makes sense to divide
        them into categories. <br /> <strong>UX-based example:</strong> The principle of least
        effort – This rule is rooted both in cognitive and{' '}
        <a
          href="https://dzone.com/articles/the-principle-of-least-effort-an-integral-part-of"
          target="_blank"
          rel="noopener noreferrer"
        >
          evolutionary psychology
        </a>
        . In short, it means that your users will choose the easiest path to get something done.
      </p>
      <h3>Gestalt psychology</h3>
      <img
        src={img3}
        alt="dots"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Dots"
      />
      <p>
        <strong>What is is</strong>: A theory created by Max Wertheimer, Gestalt states that{' '}
        <i>the whole is more than the sum of the parts</i>
        . This field of psychology explores how people perceive objects, as well as how they
        simplify complex images. <br />
        <strong>Classic example:</strong>{' '}
        <a
          href="https://uxdesign.cc/ux-psychology-go-hand-in-hand-how-gestalt-theory-appears-in-ux-design-18b727343da8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Law of proximity
        </a>{' '}
        – It’s a quite simple rule, pictured above. When people see that some objects are close to
        each other, they’re more likely to see them as one group. <br />{' '}
        <strong>UX-based example:</strong> Virtually all Gestalt laws can be applied to UX design –
        below, we’re describing the law of proximity and the law of continuity in more detail.
      </p>
      <p>
        These are the three branches of psychology that can be useful for people working in the UX
        field. Now we’ll move on to 7 psychological principles that can be applied in design:
      </p>
      <p>How exactly can you show your potential customer that your company is reliable?</p>
      <h2>1. Jakob’s law</h2>
      <p>
        Let’s start with the basic question: <i>why reinvent the wheel</i>?
      </p>
      <p>
        This is exactly what Jakob Nielsen thought. You may recognize him as the co-founder of
        Nielsen Norman Group, a famous UX consulting firm. He knows a thing or two about interfaces,
        as he worked for IBM, Microsoft and other technology giants.
      </p>
      <p>
        The idea behind Jakob’s law is simple. Your users already know how to interact with other
        websites. Why would you ask them to change their habits? If your site works like other sites
        they know, they won’t need to learn how to use it – they’ll know right away.
      </p>
      <p>
        <strong>Users expect certain functionalities to be located in certain places.</strong> One
        could come up with dozens of examples: the X is always in the upper corner of a window, the
        hamburger menu will unfold after clicking and the footer will probably contain contact
        details.
      </p>
      <figure>
        <img
          src={img4}
          alt="ls-site"
          className="img-fluid justify-content-center mb-2 mt-3"
          loading="lazy"
          title="LiveSession example"
        />
        <figcaption>
          <i>
            If the visitor would like to get in touch, they will probably scroll to the bottom of
            the page to find the “Contact us” section.
          </i>
        </figcaption>
      </figure>
      <p>
        The visitors are going to look for these features in the most likely places. This kind of
        behavior is known as a <strong>desire path</strong> – people tend to take the easy route to
        find what they’re looking for.
      </p>
      <p>The bad news is: there is no ultimate list of UX habits.</p>
      <p>
        The good news is: you can find your users’ desire paths with the help of{' '}
        <strong>session recording tools.</strong> When you watch how they interact with your
        website, you’ll soon see some common patterns emerging.
      </p>
      <a href="/signup/" target="_blank" rel="noopener noreferrer" className="action-banner">
        Try session recordings today. No credit card required!
      </a>
      <h2>2. The principle of least effort</h2>
      <p>
        We’ve mentioned desire paths above – they’re also a great illustration of{' '}
        <strong>the principle of least effort.</strong> As the name suggests, it means that people
        will do as little work as possible to get something done.
      </p>
      <p>
        There is a brilliant evolutionary explanation of this phenomenon. Our resources, including
        the cognitive ones, are limited. In order to survive, we shouldn’t be using more energy than
        needed.{' '}
      </p>
      <p>
        What’s more, in the digital age, we’re often overwhelmed with information. Our attention
        span is shorter and our time is limited. We want to achieve the goal as soon as possible,
        which is why we choose the easiest (and often the fastest) path.{' '}
      </p>
      <p>
        So, <i>what</i> is the easiest way? As in the previous case, the way to find out is to watch{' '}
        <strong>how the users interact with your website.</strong>
      </p>
      <h2>3. Law of proximity</h2>
      <p>
        One of the Gestalt principles, <strong>law of proximity</strong> is often visible in UX
        design. As we’ve mentioned above, it means that elements located close to each other are
        perceived as one group. Have a look at this simple example from{' '}
        <a href="https://rocketlink.io/" target="_blank" rel="noopener noreferrer">
          RocketLink
        </a>
        :
      </p>
      <img
        src={img5}
        alt="rocketlink"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Rocketlink example"
      />
      <p>
        All the pricing plans are described within a separate column. Thank to clear structure and
        hierarchy, the pricing is easy to understand.
      </p>
      <h2>4. Law of figure/ground</h2>
      <p>
        Another important Gestalt principle is the <strong>law of figure/ground</strong>. It states
        that an element can be perceived either as a <strong>figure</strong> (distinct point of
        focus) or as a <strong>background</strong>. This is often explained with this famous optical
        illusion:{' '}
      </p>
      <figure>
        <img
          src={img6}
          alt="two-mens"
          className="img-fluid justify-content-center mb-2 mt-3"
          loading="lazy"
          title="Optical illusion"
        />
        <figcaption>
          <i>What can you see: two heads, a vase, or maybe both?</i>
        </figcaption>
      </figure>
      <p>
        In terms of UX design, this principle can be applied through contrast, brightness or colors.
        For instance, call-to-action buttons work better when they stand out from the background,
        like in the example below:{' '}
      </p>
      <img
        src={img7}
        alt="chatbotize"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Chatbotize example"
      />
      <p>
        <a href="https://chatbotize.com/" target="_blank" rel="noopener noreferrer">
          Chatbotize
        </a>{' '}
        used a contrasting, green sign up button. Green is associated with confirmation, but in this
        case it also a great contrast with the background color. The button is easy to notice and
        the users are likely to click on it.{' '}
      </p>
      <h2>5. Law of similarity</h2>
      <p>
        Just like two previous principles, the <strong>law of similarity</strong> also comes from
        Gestalt psychology. The idea is that if two elements are similar, they are seen as parts of
        the same group. This rule is often used when describing multiple features, like in the case
        of{' '}
        <a href="https://www.cloudtalk.io/" target="_blank" rel="noopener noreferrer">
          Cloudtalk
        </a>
        :
      </p>
      <figure>
        <img
          src={img8}
          alt="cloudtalk"
          className="img-fluid justify-content-center mb-2 mt-3"
          loading="lazy"
          title="Cloudtalk example"
        />
        <figcaption>
          <i>
            Every feature is described in a separate block of text, topped with a matching icon,
            header and an orange CTA
          </i>
        </figcaption>
      </figure>
      <h2>6. Hick’s law</h2>
      <p>
        <i>Less is more</i> – this is probably the shortest way to sum up{' '}
        <strong>Hick’s law</strong>.
      </p>
      <p>
        <a
          href="https://www.interaction-design.org/literature/article/hick-s-law-making-the-choice-easier-for-users"
          target="_blank"
          rel="noopener noreferrer"
        >
          Research by William Hick
        </a>{' '}
        has shown that the more choice we have, the longer it takes to make a decision. When the
        users are overwhelmed with options, they may as well not choose anything at all. This rule
        is also known as the{' '}
        <a
          href="http://longevity.stanford.edu/2011/06/28/the-tyranny-of-choice/"
          target="_blank"
          rel="noopener noreferrer"
        >
          tyranny of choice
        </a>
        . People may expect they’ll feel happier when they can choose from a wide range, but it’s
        often the opposite – they get frustrated instead.{' '}
      </p>
      <p>
        Well, what if you actually have a <i>lot</i> on offer? Amazon and Netflix have a huge
        inventory and both services are quite successful, aren’t they?
      </p>
      <p>
        Definitely, and not without reason – they both know how to make the choice easier.
        Everything that’s on offer is grouped into tailor-made categories:
      </p>
      <img
        src={img9}
        alt="netflix"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="Netflix example"
      />
      <h2>7. Serial position effect</h2>
      <p>There’s a very specific reason why we’re describing this principle as the last one.</p>
      <p>
        <strong>Serial position effect</strong> combines two other rules – the{' '}
        <strong>primacy effect</strong> and <strong>the recency effect</strong>. The effect
        describes how people tend to remember <i>the first</i> and <i>the last</i> items in a series
        best.{' '}
      </p>
      <p>
        What does it mean for UX designers? You can think about it this way: if you need to grab
        your user’s attention with something, it pays off to repeat this thing both at the beginning
        and at the end of the page.{' '}
      </p>
      <p>
        This is a common practice on{' '}
        <a
          href="https://www.99signals.com/best-landing-page-builders/"
          target="_blank"
          rel="noopener noreferrer"
        >
          landing pages
        </a>
        . The sign-up button pops up at the top and reappears at the bottom. We used this strategy
        at LiveSession, too:
      </p>
      <img
        src={img10}
        alt="ls-site-1"
        className="img-fluid justify-content-center mb-5 mt-3"
        loading="lazy"
        title="LiveSession strategy"
      />
      <h2>Key takeaways</h2>
      <p>
        As you can see, different fields of psychology can be a goldmine of information and ideas
        for UX designers and researchers. Sticking to psychological principles can help you come up
        with more relevant design solutions. With this knowledge, you’ll find it easier to
        understand how your users think and what are the reasons behind their behavior. Last but not
        least, psychological knowledge will help you interpret data from qualitative research, such
        as session recordings.
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: '7 Psychological Principles for Better UX',
  url: '/blog/7-psychological-principles-for-better-ux',
  description:
    'User experience professionals are supposed to answer the needs of the people, find their main pain points and solve their problems through design.',
  author,
  img,
  imgSocial: img,
  date: '2019-12-11',
  category: '',
  imgStyle: { borderBottom: '1px solid rgba(0,0,0,0.125)' },
  group: 'Design',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    descriptionSocial="Once you’re familiar with the basic principles of psychology, designing experiences feels less like taking a shot in the dark. "
  />
);
